@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);

.blockinfo-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: $color3;
    color: $color5;
    padding: 1rem;
}

.blockinfo-header {
    text-transform: uppercase;
    color: $color2;
    text-align: left;
    font-size: x-large;
    font-weight: bolder;
    padding-bottom: 0rem;

}

.blockinfo-value-mono {
    display: inline;
    word-wrap: break-word;
    font-family: 'Roboto Mono';
}

.blockinfo-value {
    display: inline;
    word-wrap: break-word;
    font-family: 'Montserrat';
}


@include media-breakpoint-down(sm) {
    .blockinfo-value-mono {
        font-size: small;
    }

    .blockinfo-value {
        font-size: small;
    }
}


.blockinfo-header-id {
    color: $color2;
    overflow: auto;
    text-overflow: ellipsis;
    // font-weight: 100;
    // font-size: 1rem;
    // word-wrap: break-word;
}

.blockinfo-content {
    background-color: $color5;
    border-radius: 0.8rem;
    padding: 2rem;
    overflow: hidden;
    color: $color1;

    // color: $color1;
    // width: 100%;
    // height: 100%;
    // border-radius: 1.2rem;
    // background-color: $color5;

    // padding: 1rem;
}

@include media-breakpoint-down(sm) {
    .blockinfo-content {
        padding: 1.3rem;
        margin: 0;
    }
}

.blockinfo-table {
    text-align: left;
    padding: 0;
}

@include media-breakpoint-up(sm) {
    .blockinfo-table {
        padding: 0 1rem;
    }
}

.block-utxo-amount {
    color: rgb(33, 192, 46);
    white-space: nowrap;
}

.block-utxo-amount-minus {
    color: rgb(255, 82, 59);
    white-space: nowrap;
}



.blockinfo-row {
    padding: 0.5rem 0rem;
    // overflow-wrap: break-word;
    overflow: hidden;
    word-wrap: nowrap;
    border-bottom: 1px solid rgba($color3, 0.3);
}

.blockinfo-key {
    font-weight: bold;
    text-align: left;
}

@include media-breakpoint-down(sm) {
    .blockinfo-key {
        font-size: small;
    }
}

.tooltip-inner {
    opacity: 1 !important;
    background-color: $color3;
    filter: alpha(opacity=100);
}

.tooltip.in {
    opacity: 1 !important;
    filter: alpha(opacity=100);
}

.tooltip-arrow::before {
    opacity: 1;
    border-top-color: $color3  !important;
}

@include media-breakpoint-up(xl) {
    .blockinfo-page {
        padding: 5rem
    }

    .blockinfo-row {
        padding: 1rem 0rem;
    }
}
@include media-breakpoint-down(sm) {
    .blockinfo-page {
        padding: 2rem 0rem;
    }

}


#searchbox {
    width: 10rem;
}

#searchbox:focus {
    width: 20rem;
    transition: width 0.3s;
}

.blockinfo-link {
    color: $color1;
    text-decoration: none;
}

.blockinfo-link:hover {
    color: $color2;
    text-decoration: none;
}

.block-lamp-blue {
    background-color: rgb(30, 128, 207);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    // margin-left: 2rem;
}

.block-lamp-red {
    background-color: rgb(202, 47, 27);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}


.transactions-link {
    color: $color1;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;
    border: 1px solid red;
}

.transactions-link:hover {
    color: $color2;
}

.utxo-amount {
    background-color: rgb(65, 141, 65);
    padding: .2rem .7rem;
    border-radius: .4rem;
    word-wrap: none;
    display: inline-block;
    
}
.utxo-amount-minus {
    background-color: rgb(195, 38, 38);
    padding: .2rem .7rem;
    border-radius: .4rem;
    word-wrap: none;
    display: inline-block;
}

.table-timestamp {
    word-wrap: normal;
    flex-wrap: nowrap;
}

.accepted-true {
    background-color: rgb(65, 141, 65);
    padding: 0.2em .4rem;
    border-radius: .4rem;

}

.accepted-false {
    background-color: rgb(255, 82, 59);
    padding: 0.2em .4rem;
    border-radius: .4rem;
}

.confirmations {
    background-color: rgb(65, 141, 65);
    padding: 0.2em .4rem;
    border-radius: .4rem;
}

//  .utxo-amount::before {
//     content: "+";

//  }
